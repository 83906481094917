import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TablePagination,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../component/tabel/DataTabel";
import ShortingTable from "../../component/tabel/ShortingTabel";
import {
  useGetBrandAllOrders,
  useGetExportExcelOrders,
  useGetPlatform,
  useGetStatus,
} from "../../component/customHooks/customHook";

import SortPopupComponent from "../../common/sort/sortPopupComponent";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

import OrderViewDetails from "../../component/viewdetails/orderViewDetails";
import FilterPopupComponent from "../../common/filter/filterPopup";
import NoteHistory from "../../component/adminhistory/NoteHistory";
import AdminNotePopup from "../../component/adminnote/AdminNotePopup";
import HistoryIcon from "@mui/icons-material/History";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import {
  getAxios,
  postJsonData,
  putJsonData,
} from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import { AlignHorizontalLeft } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setOrderFilter } from "../../store/FilterSlice";
import DateRangeFilter from "../../component/datefilter/DateRangeFilter";
import RateReviewIcon from "@mui/icons-material/RateReview";
import AddReviewPopup from "../../component/adminnote/AddReviewPopup";
import { SmallButton } from "../../component/coustombutton/SmallButton";
import * as XLSX from "xlsx";
import CryptoJS from "crypto-js";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { useLocation } from "react-router-dom";

const sortArray = [
  {
    sortOrder: "desc",
    sortField: "updatedAt",
    sortName: "Updated Date (Dsc)",
  },
  {
    sortOrder: "asc",
    sortField: "orderDate",
    sortName: "Order Date (Asc)",
  },
];

const OrderComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { campaignId } = location.state || "";

  // useEffect(() => {
  //   if (campaignId) {
  //     setParam({ campaignname: campaignId });
  //   }
  // }, [campaignId]);

  useEffect(() => {
    if (campaignId) {
      // Convert the object to a string
      const filterString = `campaignname=${campaignId}`;
      setParam(filterString);
    }
  }, [campaignId]);

  const filterParam = useSelector((state) => state.filter.orderFilter);

  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [editState, setEditState] = useState();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [influencerDrawerOpen, setInfluencerDrawerOpen] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [rowDatanew, setrowDatanew] = useState(null);
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);
  const [openModalNote, setOpenModalNote] = useState(false);
  const [openReviewNote, setopenReviewNote] = useState(false);
  const [perPageLimit, setPerPageLimit] = useState(25);
  const [row, setRow] = useState();
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [isDataReady, setIsDataReady] = useState(false);
  const [flattenData, setFlattenData] = useState([]);
  const [decryptedBrandId, setDecryptedBrandId] = useState(null);
  const [allstatusExport, setAllstatusExport] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const role = localStorage.getItem("userRole");
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 30 },
    { id: "orderid", label: "Order Id", minWidth: 200 },
    { id: "camapignname", label: "Campaign", minWidth: 180 },
    { id: "product", label: "Product", minWidth: 50 },
    { id: "platform", label: "Platform", minWidth: 50 },
    { id: "amount", label: "Amount", minWidth: 30 },
    { id: "orderdate", label: "Order Date", minWidth: 30 },
    { id: "status", label: "Status", minWidth: 80 },
    // { id: "reimbursed", label: "Reimbursed?", minWidth: 50 },
  ];

  useEffect(() => {
    const encryptedBrandId = localStorage.getItem("userid");
    if (encryptedBrandId) {
      const bytes = CryptoJS.AES.decrypt(
        encryptedBrandId,
        process.env.REACT_APP_SECRET_KEY
      );
      const decryptedId = bytes.toString(CryptoJS.enc.Utf8);
      setDecryptedBrandId(decryptedId);
    }
  }, []);
  const { allstatus, loading, totalPages, totalCount } = useGetBrandAllOrders(
    decryptedBrandId,
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh
  );
  const { allstatus: newFilterData } = useGetBrandAllOrders(decryptedBrandId);

  const fetchuseGetCamapign = () => {
    if (decryptedBrandId && shouldFetch) {
      setIsLoading(true);
      const endpoint =
        `${VisitorEndpoint.EXPORT_EXCEL_ORDERS}/${decryptedBrandId}` +
        (filterParam ? `?${filterParam}` : "");
      const axiosInstance = getAxios();

      axiosInstance
        .get(endpoint)
        .then((response) => {
          // setLoading(false);
          setAllstatusExport(response.data.data);
          setShouldFetch(false);

          const newData = response.data.data.map((item) => {
            // console.log(item)
            const newItem = {
              "Campaign Name": item["Campaign Name"],
              "Order Id": item["Order Id"],
              "Order Date": item["Order Date"],
              Influencer: item.Influencer,
              Product: item.Product,
              "Order Platform": item["Order Platform"],
              "Order Value": item["Order Value"],
              "Order Status": item["Order Status"],
            };
            if (item["Reel Link/Live Link"]) {
              newItem["Reel Link/Live Link"] = {
                  "v": item["Reel Link/Live Link"],
                  "l": {
                      "Target": item["Reel Link/Live Link"],
                      "Tooltip": "Click to visit the reel link/Live Link",
                  },
              }
          }
            if (item["Order Screenshot"]) {
              if (Array.isArray(item["Order Screenshot"])) {
                  item["Order Screenshot"]?.forEach((sm, index) => {
                      newItem[`Order Screenshot ${index + 1}`] = {
                          v: sm, 
                          l: {
                              Target: `${sm}`, 
                              Tooltip: "Click to visit order screenshot", 
                          },
                      };
                  });
              }
          }
          
          if (item["Review Screenshot"]) {
              if (Array.isArray(item["Review Screenshot"])) {
                  item["Review Screenshot"]?.forEach((sm, index) => {
                      newItem[`Review Screenshot ${index + 1}`] = {
                          v: sm, 
                          l: {
                              Target: `${sm}`, 
                              Tooltip: "Click to visit review screenshot",
                          },
                      };
                  });
              }
          }
          
          //   if (item["Order Screenshot"]) {
          //     if (Array.isArray(item["Order Screenshot"])) {
          //     item["Order Screenshot"]?.forEach((sm, index) => {
          //             newItem[`Order Screenshot ${index + 1}`] = sm;
                
          //     });  }
          // }
          
          //   if (item["Review Screenshot"]) {
          //     if (Array.isArray(item["Review Screenshot"])) {
          //     item["Review Screenshot"]?.forEach((sm, index) => {
          //             newItem[`Review Screenshot ${index + 1}`] = sm;
                
          //     });  }
          // }
          
            delete newItem._id;
            return newItem;
          });

          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          XLSX.writeFile(wb, "orderDetailsExcel.xlsx");

          setIsLoading(false);
        })
        .catch((error) => {
          // toast.error(error.response.data.message);;
          setIsLoading(false);
        });
    }
  };
 
  // console.log(orderDetailsExcel);
  const orderIdFilter = newFilterData?.map((item) => ({
    orderId: item.orderId,
  }));
  const campaignData = newFilterData?.map((item) => ({
    id: item.campaign._id,
    name: item.campaign.campaignName,
  }));

  const uniqueCampaignData = campaignData.filter(
    (campaign, index, self) =>
      index ===
      self.findIndex((c) => c.id === campaign.id && c.name === campaign.name)
  );

  const productData = newFilterData?.map((item) => ({
    id: item.product?._id,
    name: item.product?.productName,
  }));

  const uniqueProductData = productData.filter(
    (product, index, self) =>
      index ===
      self.findIndex((c) => c.id === product.id && c.name === product.name)
  );

  const platformData = newFilterData?.map((item) => ({
    id: item.orderPlatform._id,
    name: item.orderPlatform.platform,
  }));

  const uniquePlatformData = platformData.filter(
    (orderPlatform, index, self) =>
      index ===
      self.findIndex(
        (c) => c.id === orderPlatform.id && c.name === orderPlatform.name
      )
  );

  const status = useGetStatus("", "", "BrandOrders");

  const realData = allstatus.map((item) => ({
    id: item._id,
    sno: item.sno,
    brandname: item.brand ? item.brand.brandName : "",
    orderid: item.orderId ? item.orderId : "",
    camapignname: item.campaign ? item.campaign.campaignName : "",
    // product: item.product && item.product?.productName ? "...":null,
    product: item.product ? item.product?.productName : null,
    amount: item.orderValue && item.orderValue,
    platform: item.orderPlatform && item.orderPlatform.platform,
    status: item.brandStatus && item.brandStatus?.status,
    orderdate: new Date(item.orderDate).toISOString().split("T")[0],
    // reimbursed:
    //   item.status.status &&
    //   (item.status.status === "Closed" || item.status.status === "Reimbursed")
    //     ? "Yes"
    //     : "No",
    // orderplatform: item.orderPlatform.platform,
  }));

  // const statuses = realData.map(item => item.status);

  let filters = [
    {
      label: "Order Id",
      urlParam: "orderid",
      values: orderIdFilter?.map((item) => ({
        label: item.orderId,
        value: item.orderId,
      })),
    },
    {
      label: "Campaign Name",
      urlParam: "campaignname",
      values: uniqueCampaignData?.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    },
    {
      label: "Product Name",
      urlParam: "productname",
      values: uniqueProductData?.map((item) => ({
        label: item.name.substring(0, 50),
        value: item.id,
      })),
    },
    {
      label: "Platform",
      urlParam: "platform",
      values: uniquePlatformData?.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    },
    {
      label: "Order Status",
      urlParam: "brandstatus",
      values: status.allstatus.map((item) => ({
        label: item.status,
        value: item._id,
      })),
    },
  ];
  const refreshData = () => {
    setTriggerRefresh(!triggerRefresh);
  };
  const actions = [
    {
      icon: NoteAddIcon,

      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setEditState(selectedData);
        handleOpenModal();
      },
    },
    {
      icon: HistoryIcon,

      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        handleOpenHistoryModal();
      },
    },
  ];
  const setRowFunction = (row) => {
    const selectedData = allstatus.find((item) => item._id === row.id);
    setRow(selectedData);
  };
  const handleRowsPerPageChange = (event) => {
    setPerPageLimit(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const startIndex = (currentPage - 1) * perPageLimit + 1;
  const endIndex = Math.min(startIndex + perPageLimit - 1, totalCount);
  const handleChangePage = (event, newPage) => {
    if (newPage > currentPage - 1 && endIndex < totalCount) {
      setCurrentPage(newPage + 1);
    } else if (newPage < currentPage - 1 && startIndex > 1) {
      setCurrentPage(newPage + 1);
    }
  };

  const setParam = (value) => {
    if (filterParam !== value) {
      dispatch(setOrderFilter(value));
      setCurrentPage(1);
      setTriggerRefresh((prev) => !prev);
    }
  };

  const selectSort = (sortFieldValue, sortOrderValue) => {
    setSortField(sortFieldValue);
    setSortOrder(sortOrderValue);
  };

  useEffect(() => {
    if (shouldFetch) {
      fetchuseGetCamapign();
    }
  }, [shouldFetch]);

  const handleButtonClick = () => {
    setShouldFetch(true);
  };

  const handleOpenModal = () => {
    setOpenModalNote(true);
  };

  const handleCloseModal = () => {
    setOpenModalNote(false);
  };
  const handleOpenHistoryModal = () => {
    setHistoryDrawerOpen(true);
  };

  return (
    <>
      <OrderViewDetails
        influencerDrawerOpen={influencerDrawerOpen}
        setInfluencerDrawerOpen={setInfluencerDrawerOpen}
        rowData={selectedRowData}
      />
      <NoteHistory
        historyDrawerOpen={historyDrawerOpen}
        setHistoryDrawerOpen={setHistoryDrawerOpen}
        rowData={selectedRowData}
      />
      <AdminNotePopup
        open={openModalNote}
        onClose={handleCloseModal}
        statusGroup="BrandOrders"
        editState={editState}
        endpoint="BRAND_ORDER_NOTE"
        onSuccess={refreshData}
      />

      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Order", href: "/brand/order" },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>Order</p>
              <span>{totalCount}&nbsp;Total Order</span>
            </div>

            <div style={{ display: "flex", gap: "10px" }}>
              <div>
                <SmallButton
                  background="#fff"
                  color="#163e6e"
                  onClick={handleButtonClick}
                  label={isLoading ? "Loading..." : "Export Excel"}
                  startIcon={<SimCardDownloadIcon />}
                />
              </div>
            </div>
          </div>
       
          <div className="campaign_filter_style">
            <SortPopupComponent
              sortFunction={selectSort}
              sortArray={sortArray}
            />
            {/* <div style={{marginLeft:"10px"}}> */}

            <DateRangeFilter
              onApply={({ startDate, endDate }) => {
                // Use the dates to form the parameters
                const params = `orderdate[$lte]=${endDate}&orderdate[$gte]=${startDate}`;
                setParam(params);
              }}
              filterName="Order Date"
            />

            <FilterPopupComponent
              filters={filters}
              onChange={setParam}
              isOpen={isOpen}
              filterKey="orderFilter"
            />
          </div>
        
          <ShortingTable
            columns={columns}
            loading={loading}
            data={realData}
            actions={actions}
            rowFunction={setRowFunction}
            onClickLink={(row) => {
              const selectedData = allstatus.find(
                (item) => item._id === row.id
              );
              setSelectedRowData(selectedData);
              setInfluencerDrawerOpen(true);
            }}
            onClickTooltip={(row) => {
              const selectedData = allstatus.find(
                (item) => item._id === row.id
              );
              // setSelectedRowData(selectedData);
            }}
            movertShowIcon={false}
          />
          <div style={{paddingTop: loading?  "40px": 0}}>

          <TablePagination
            rowsPerPageOptions={[20, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={perPageLimit}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelDisplayedRows={({ from, to, count }) =>
              `${startIndex}-${endIndex} of ${count}`
            }
            sx={{
              fontFamily: '"Outfit", sans-serif',
              fontWeight: 600,
              fontSize: "14px",
              color: "#000",
            }}
          />
        </div>
        </div>
      </div>
    </>
  );
};
export default OrderComponent;
