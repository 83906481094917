import React, { useEffect, useState } from "react";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";
import CardComponent from "../../common/card/CardComponent";
import PaidIcon from "@mui/icons-material/Paid";
import {

  useGetAllOrdersBrandDashboard,
  useGetAllOrdersByPlatformBrandDashboard,
  useGetAllOrdersByProductBrandDashboard,
  useGetAllStatsBrand,

} from "../../component/customHooks/customHook";
import { useUser } from "../../contexts/UserDataStore";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import {
  InfluencerSvg,
  InfluencersvgBlack,
  NoDataImage,
  Order,
  PaymentBlackHeader,
  Paymentblack,
  Reimbursement,
  WebRegisterationImage,
} from "../../component/IconsImport";
import CampaignIcon from "@mui/icons-material/Campaign";
import { Girl, Loyalty } from "@mui/icons-material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  BarChart,
} from "recharts";
import { Card, CardContent, Divider, Grid } from "@mui/material";
import { CustomRequiredLabel } from "../../common/styledCcomponents/styledInput";
import ShortingTable from "../../component/tabel/ShortingTabel";
import CryptoJS from "crypto-js";

// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
const DashboardComponent = () => {
  const { user } = useUser();

  const [decryptedName, setDecryptedName] = useState(null);
  const [decryptedBrandId, setDecryptedBrandId] = useState(null);

  useEffect(() => {
    const encryptedName = localStorage.getItem("name");
    if (encryptedName) {
      const bytes = CryptoJS.AES.decrypt(
        encryptedName,
        process.env.REACT_APP_SECRET_KEY
      );
      const decryptedName = bytes.toString(CryptoJS.enc.Utf8);
      setDecryptedName(decryptedName);
    }
  }, []);

  useEffect(() => {
    const encryptedBrandId = localStorage.getItem("userid");
    if (encryptedBrandId) {
      const bytes = CryptoJS.AES.decrypt(
        encryptedBrandId,
        process.env.REACT_APP_SECRET_KEY
      );
      const decryptedId = bytes.toString(CryptoJS.enc.Utf8);
      setDecryptedBrandId(decryptedId);
    }
  }, []);
  const { allstatus: AllBrandStats, loading: AllBrandStatsLoading } =
    useGetAllStatsBrand(decryptedBrandId);




  const orderChart = useGetAllOrdersBrandDashboard(decryptedBrandId);

  const orderByProductName = useGetAllOrdersByProductBrandDashboard(
    decryptedBrandId
  );
  const orderByPlatformName = useGetAllOrdersByPlatformBrandDashboard(
    decryptedBrandId
  );



  const columns = [
    { id: "sno", label: "S.No.", minWidth: 100 },
    { id: "productname", label: "Product name", minWidth: 200 },
    { id: "totalrequest", label: "Total Requested Orders", minWidth: 50 },

    { id: "totalorders", label: "Total Completed Orders", minWidth: 100 },
    { id: "totalrvieworders", label: "Total Reviewed Orders", minWidth: 100 },
    {
      id: "totalCancelledOrReturned",
      label: "Total Cancelled Or Returned",
      minWidth: 200,
    },
  ];
  const columnsPlatform = [
    { id: "sno", label: "S.No.", minWidth: 100 },
    { id: "platformname", label: "Platform", minWidth: 100 },

    { id: "totalorders", label: "Total Completed Orders", minWidth: 100 },
    { id: "totalrvieworders", label: "Total Reviewed Orders", minWidth: 100 },
    {
      id: "totalCancelledOrReturned",
      label: "Total Cancelled Or Returned",
      minWidth: 150,
    },
  ];
  const columnsOrderData = [
    { id: "sno", label: "S.No.", minWidth: 50 },
    { id: "dateoforders", label: "Date Of Orders", minWidth: 100 },
    { id: "numberoforders", label: "No. Of Orders", minWidth: 100 },
  ];
  const realOrderData = orderChart?.allstatus[0]?.orders.map((item, index) => ({
    sno: index + 1,
    dateoforders: item.date && item.date,
    numberoforders: item.totalOrders && item.totalOrders,
  }));
  const realData = orderByProductName?.allstatus.map((item, index) => ({
    sno: index + 1,
    productname: item.productName && item.productName,
    totalrequest: item.totalDesiredOrders && item.totalDesiredOrders,
    totalorders: item.totalOrders && item.totalOrders,
    totalrvieworders:
      item.totalClosedOrReimbursed && item.totalClosedOrReimbursed,
    totalCancelledOrReturned:
      item.totalCancelledOrReturned && item.totalCancelledOrReturned,
  }));
  const realPlatformData = orderByPlatformName?.allstatus.map(
    (item, index) => ({
      sno: index + 1,
      platformname: item.platformName && item.platformName,
      totalorders: item.totalOrders && item.totalOrders,
      totalrvieworders:
        item.totalClosedOrReimbursed && item.totalClosedOrReimbursed,
      totalCancelledOrReturned:
        item.totalCancelledOrReturned && item.totalCancelledOrReturned,
    })
  );

  return (
    <>
      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            <ActiveLastBreadcrumb links={[{ name: "Dashboard", href: "/" }]} />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>
                Welcome{" "}
                {decryptedName &&
                  decryptedName.charAt(0).toUpperCase() +
                    decryptedName.slice(1)}
              </p>
            </div>
          </div>
          <div className="card_container">
            <CardComponent
              logoColor="green"
              icon={CampaignIcon}
              data={AllBrandStats[0]?.totalCampaign}
              heading="Total Campaigns"
              loading={AllBrandStatsLoading}
              width="250px"
            />
            <CardComponent
              logoColor="purple"
              icon={CampaignIcon}
              data={AllBrandStats[0]?.activeCampaigns}
              heading="Active Campaigns"
              loading={AllBrandStatsLoading}
              width="250px"
            />

            <CardComponent
              logoColor="purple"
              icon={CampaignIcon}
              data={AllBrandStats[0]?.completedCampaigns}
              heading="Completed Campaigns"
              loading={AllBrandStatsLoading}
              width="250px"
            />
            <CardComponent
              logoColor="pink"
              icon={CampaignIcon}
              heading="Paused Campaign"
              width="250px"
              data={AllBrandStats[0]?.pausedCampaigns}
              loading={AllBrandStatsLoading}
            />
            <CardComponent
              logoColor="purple"
              image={Order}
              heading="Total Requested Orders"
              data={AllBrandStats[0]?.totalRequestedOrders}
              width="250px"
              loading={AllBrandStatsLoading}
            />
            <CardComponent
              logoColor="purple"
              image={Order}
              heading="Total Completed Orders"
              data={AllBrandStats[0]?.totalPlacedOrders}
              width="250px"
              loading={AllBrandStatsLoading}
            />

            <CardComponent
              logoColor="green"
              image={Order}
              heading="Total Pending Orders"
              data={AllBrandStats[0]?.totalPendingOrders}
              color={"red"}
              width="250px"
              loading={AllBrandStatsLoading}
            />
            <CardComponent
              logoColor="pink"
              image={Order}
              heading="Total Reviewed Orders"
              data={AllBrandStats[0]?.totalReviewedOrders}
              width="250px"
              loading={AllBrandStatsLoading}
            />

            <CardComponent
              logoColor="blue"
              image={PaymentBlackHeader}
              data={AllBrandStats[0]?.pendingPayment?.toFixed(2)}
              heading="Pending Payment"
              color={"red"}
              loading={AllBrandStatsLoading}
              width="250px"
            />

            <CardComponent
              logoColor="blue"
              image={PaymentBlackHeader}
              data={AllBrandStats[0]?.totalExhaustedCost?.toFixed(2)}
              heading="Exhausted Order Cost"
              loading={AllBrandStatsLoading}
              width="250px"
            />

            <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
              <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                {/* <Card sx={{ width: "100%" }}>
                  <CardContent> */}
                    <h1 className="country_text">Total Orders (Last 10 Days)</h1>

                    {/* {orderChart?.allstatus &&
                    orderChart?.allstatus.length > 0 ? ( */}
                      <ShortingTable
                        columns={columnsOrderData}
                        // loading={activeCampaignDetailsLoading}
                        data={orderChart?.allstatus &&
                    orderChart?.allstatus.length > 0 ?realOrderData:[]}
                      />
                    {/* ) : (
                      <div>
                        <CustomRequiredLabel>
                          No Data Available
                        </CustomRequiredLabel>
                        <img src={NoDataImage} />
                      </div>
                    )} */}
                  {/* </CardContent>
                </Card> */}
              </Grid>
              <Grid item xs={12} sm={8} sx={{ paddingBottom: "15px" }}>
                {/* <Card>
                  <CardContent> */}
                    <h1 className="country_text">Total Orders By Product</h1>
                    <ShortingTable
                      columns={columns}
                      // loading={activeCampaignDetailsLoading}
                      data={realData}
                    />

                    <Divider sx={{ marginY: 2, borderBottom: "none" }} />
                    <h1 className="country_text">Total Orders By Platform</h1>
                    <ShortingTable
                      columns={columnsPlatform}
                      // loading={activeCampaignDetailsLoading}
                      data={realPlatformData}
                    />
                  {/* </CardContent>
                </Card> */}
              </Grid>
            </Grid>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};
export default DashboardComponent;
