import React, { useState, useEffect } from "react";
import { PATTERNS } from "../../component/validetion";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  CircularProgress,
  Modal,
  Box,
} from "@mui/material";
import {
  CustomRequiredLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import SubmitButton from "../../component/coustombutton/SubmitButton";
import { useGetInfluencerAllOrdersForReview } from "../../component/customHooks/customHook";
import {
  patchFormNewData,
  postFormData,
} from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";
import SingleSelect from "../../common/select/SingleSelect";
import CloseIcon from "@mui/icons-material/Close";
import Resizer from 'react-image-file-resizer';

const AddReviewMarq = () => {
  const [formData, setFormData] = useState({
    camapignname: {
      value: "",
      isValid: true,
    },
    influncername: {
      value: "",
      isValid: true,
    },
  });

  const [reviewScreenshotFile, setReviewScreenshotFile] = useState(null);
  const [isDisabledNew, setIsDisabledNew] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [image, setImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedDeliveredImages, setSelectedDeliveredImages] = useState([]);
  const [currentImage, setCurrentImage] = useState("");
  const [openZoomModal, setOpenZoomModal] = useState(false);
  const [daysToSubtract, setDaysToSubtract] = useState(10);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorDelMessage, setDelErrorMessage] = useState('');

  const { allstatus: OrderDetails, loading } =
    useGetInfluencerAllOrdersForReview();

  // const currentDate = new Date();
  // const filteredOrders = OrderDetails.filter((item) => {
  //   const deliveryDate = new Date(item.deliveryDate);
  //   const tenDaysBeforeCurrentDate = new Date(currentDate);
  //   tenDaysBeforeCurrentDate.setDate(currentDate.getDate() - 10);
  //   // Return true tabhi hoga jab delivery date last 10 din ke andar ho
  //   return deliveryDate >= tenDaysBeforeCurrentDate;
  // });
    
  useEffect(() => {
    fetch('/config.json')
      .then(response => response.json())
      .then(data => {
        setDaysToSubtract(data.daysToSubtract);
      })
      .catch(err => console.error( err));
  }, []);
  
  const currentDate = new Date();
  const filteredOrders = OrderDetails.filter((item) => {
    const deliveryDate = new Date(item.deliveryDate);
    const tenDaysBeforeCurrentDate = new Date(currentDate);
    tenDaysBeforeCurrentDate.setDate(currentDate.getDate() - daysToSubtract);
    return deliveryDate >= tenDaysBeforeCurrentDate;
  });
  const handleOrderId = (selectedOption) => {
    if (selectedOption) {
      const selectedOrder = OrderDetails.find(
        (order) => order._id === selectedOption.value
      );
      setSelectedOrder(selectedOrder);
    } else {
      setSelectedOrder("");
    }
  };

  // const handleReviewScreenShot = (e) => {
  //   setReviewScreenshotFile(e.target.files[0]);
  //   setImage(URL.createObjectURL(e.target.files[0]));
  // };
  //     const handleReviewScreenShot = (e) => {
  //     setSelectedImages([...selectedImages, ...e.target.files]);
  // };
  // const handleReviewScreenShot = (e) => {
  //   setSelectedImages([
  //     ...selectedImages,
  //     ...Array.from(e.target.files).map((file) => ({
  //       file,
  //       url: URL.createObjectURL(file),
  //     })),
  //   ]);
  //   e.target.value = null;
  // };

  const handleReviewScreenShot = async (e) => {  
    const newFiles = Array.from(e.target.files);  
  
    // Function to compress a single image with a target size  
    const compressImage = async (file) => {  
      const maxSize = 40 * 1024; // Target size 40 KB  
      let quality = 90; // Start with a high quality  
      let compressedFile;  
  
      do {  
        compressedFile = await new Promise((resolve) => {  
          Resizer.imageFileResizer(  
            file,  
            1080, // Max width  
            1080, // Max height  
            'JPEG', // Output type  
            quality, // Quality (0-100)  
            0, // Rotation  
            (uri) => {  
              resolve(uri);  
            },  
            'blob' // Return type  
          );  
        });  
  
        // Decrease quality for the next attempt if still too large  
        if (compressedFile.size > maxSize) {  
          quality -= 10; // Decrease quality by 10 for the next attempt  
        }  
      } while (compressedFile.size > maxSize && quality > 0); // Loop until size is under 40 KB or quality is 0  
  
      // Return the compressed file along with the original file's name  
      return {  
        file: compressedFile,  
        name: file.name // Original file name  
      };  
    };  
  
    // Compress files larger than 40 KB  
    const compressedFiles = await Promise.all(newFiles.map((file) => compressImage(file)));  
  
    // Calculate total size of compressed files  
    const newFilesTotalSize = compressedFiles.reduce((acc, { file }) => acc + file.size, 0);  
    const existingFilesTotalSize = selectedImages.reduce((acc, imgObj) => acc + imgObj.file.size, 0);  
    const totalSize = newFilesTotalSize + existingFilesTotalSize;  
  
    // Check if total size exceeds 100 KB  
    if (totalSize > 100 * 1024) {  
      setErrorMessage('Please upload the review screenshot below 100 KB.');  
      e.target.value = null;  
      return;  
    }  
  
    setErrorMessage('');  
  
    // Update selected images  
    setSelectedImages([  
      ...selectedImages,  
      ...compressedFiles.map(({ file, name }) => {  
       
        return {  
          file,  
          url: URL.createObjectURL(file),  
          originalName: name 
        };  
      }),  
    ]);
    
    e.target.value = null;  
  };
  const removeImage = (indexToRemove) => {
    setSelectedImages(
      selectedImages.filter((_, index) => index !== indexToRemove)
    );
  };
  // const handledeliveredScreenShot = (e) => {
  //   setSelectedDeliveredImages([
  //     ...selectedDeliveredImages,
  //     ...Array.from(e.target.files).map((file) => ({
  //       file,
  //       url: URL.createObjectURL(file),
  //     })),
  //   ]);
  //   e.target.value = null;
  // };

  const handledeliveredScreenShot = async (e) => {
    const newFiles = Array.from(e.target.files);
  
    // Function to compress a single image with a target size
    const compressImage = async (file) => {
      const maxSize = 40 * 1024; // Target size 40 KB
      let quality = 90; // Start with a high quality
      let compressedFile;
  
      do {
        compressedFile = await new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            1080, // Max width
            1080, // Max height
            'JPEG', // Output type
            quality, // Quality (0-100)
            0, // Rotation
            (uri) => {
              resolve(uri);
            },
            'blob' // Return type
          );
        });
  
        // Decrease quality for the next attempt if still too large
        if (compressedFile.size > maxSize) {
          quality -= 10; // Decrease quality by 10 for the next attempt
        }
      } while (compressedFile.size > maxSize && quality > 0); // Loop until size is under 40 KB or quality is 0
  
      // Return the compressed file along with the original file's name
      return {
        file: compressedFile,
        name: file.name, // Original file name
      };
    };
  
    // Compress files larger than 40 KB
    const compressedFiles = await Promise.all(newFiles.map((file) => compressImage(file)));
  
    // Calculate total size of compressed files
    const newFilesTotalSize = compressedFiles.reduce((acc, { file }) => acc + file.size, 0);
    const existingFilesTotalSize = selectedDeliveredImages.reduce((acc, imgObj) => acc + imgObj.file.size, 0);
    const totalSize = newFilesTotalSize + existingFilesTotalSize;
  
    // Check if total size exceeds 100 KB
    if (totalSize > 100 * 1024) {
      setDelErrorMessage('*Please upload the delivery screenshot below 100 KB.');
      e.target.value = null; // Clear the input if size exceeds the limit
      return;
    }
  
    setDelErrorMessage('');
  
    // Update selected delivered images
    setSelectedDeliveredImages([
      ...selectedDeliveredImages,
      ...compressedFiles.map(({ file, name }) => ({
        file,
        url: URL.createObjectURL(file),
        originalName: name, // Save original filename here
      })),
    ]);
  
    e.target.value = null; // Clear the input
  };
  
  const removeDeliveredImage = (indexToRemove) => {
    setSelectedDeliveredImages(
      selectedDeliveredImages.filter((_, index) => index !== indexToRemove)
    );
  };
  const navigate = useNavigate();

  const handleReview = (e) => {
    e.preventDefault();
    setIsDisabledNew(true);
    setFormSubmitted(true);

    const isFormValid =
      selectedOrder &&
      selectedImages?.length > 0 &&
      selectedDeliveredImages?.length > 0;
    if (isFormValid) {
      const data = {};
      const newData = new FormData();
      Object.keys(data).forEach((key) => {
        newData.append(key, data[key]);
      });
      // if (selectedImages) {
      //   selectedImages.forEach((file, index) => {
      //     newData.append("reviewScreenshot", file.file);
      //   });
      // }
      if (selectedImages) {
        selectedImages.forEach(({ file, originalName }) => {
          // Convert the blob to a File object with the original name
          const fileWithOriginalName = new File([file], originalName, {
            type: file.type,
          });
      
          // Append the new File object to the FormData
          newData.append("reviewScreenshot", fileWithOriginalName);
        });
      }
      
      // if (selectedDeliveredImages) {
      //   selectedDeliveredImages.forEach((file, index) => {
      //     newData.append("deliveredScreenshot", file.file);
      //   });
      // }

      if (selectedDeliveredImages) {
        selectedDeliveredImages.forEach(({ file, originalName }) => {
          // Convert the blob to a File object with the original name
          const fileWithOriginalName = new File([file], originalName, {
            type: file.type,
          });
      
          // Append the new File object to the FormData
          newData.append("deliveredScreenshot", fileWithOriginalName);
        });
      }
      let endpoint = `${VisitorEndpoint.ADD_ORDER__REVIEW}/${selectedOrder?._id}`;

      patchFormNewData(
        endpoint,
        newData,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 200) {
              toast.success(res.data.message);
              navigate("/influencer/order");
            }
          }
          setIsDisabledNew(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);
        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);
    }
  };
  // const removeImage = () => {
  //   setImage(null);
  // };
  const handleOpenZoomModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setOpenZoomModal(true);
  };
  const handleCloseZoomModal = () => {
    setOpenZoomModal(false);
  };
  return (
    <>
      <Modal
        open={openZoomModal}
        onClose={handleCloseZoomModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
          }}
        >
          <img
            src={currentImage}
            alt="Zoomed"
            style={{ maxWidth: "100%", maxHeight: "80vh" }}
          />
        </Box>
      </Modal>
      <div className="container_fluid_new">
        <div className="container_new">
          {/* {loading ? (
          <CircularProgress />
        ) : (
          <> */}
          <div>
            {" "}
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/influencer/" },
                { name: "Order", href: "/influencer/order" },
                { name: "Add Review", href: "/influencer/review/add" },
              ]}
            />
          </div>
          <h1 className="have_solution_heading">Add Review</h1>

          <form onSubmit={handleReview}>
            <Card sx={{ padding: "20px" }}>
              <CardContent>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>Order Id</CustomRequiredLabel>
                    <CustomSubheadingh>
                      Select the Id for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <SingleSelect
                        options={filteredOrders?.map((item) => ({
                          label: item.orderId,
                          value: item._id,
                        }))}
                        value={selectedOrder}
                        onChange={handleOrderId}
                        name="flavor"
                      />
                      {formSubmitted && !selectedOrder && (
                        <p className="input_text_error">
                          *Please select an Order Id.
                        </p>
                      )}
                      {!loading &&
                        filteredOrders &&
                        filteredOrders.length <= 0 && (
                          <p className="input_text_error">
                            *No orders pending for review.
                          </p>
                        )}
                    </FormControl>
                  </Grid>
                </Grid>
                {/* 
              {selectedOrder && (
                <> */}
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel>Brand Name</CustomRequiredLabel>
                    <CustomSubheadingh>
                      Select the brand for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        name="reviewscreenshot"
                        type="text"
                        placeholder="Brand Name"
                        id="outlined-required"
                        label="First Name"
                        helper
                        size="small"
                        disabled
                        value={selectedOrder && selectedOrder.brand.brandName}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel>Influencer Name</CustomRequiredLabel>
                    <CustomSubheadingh>
                      Select the Influencer for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        name="reviewscreenshot"
                        type="text"
                        placeholder="Influencer Name
"
                        id="outlined-required"
                        label="First Name"
                        helper
                        size="small"
                        disabled
                        value={
                          selectedOrder &&
                          selectedOrder.influencer.influencerName
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel>Product Name</CustomRequiredLabel>
                    <CustomSubheadingh>
                      Select the product for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        name="reviewscreenshot"
                        type="text"
                        placeholder="Product Name"
                        id="outlined-required"
                        label="First Name"
                        helper
                        size="small"
                        disabled
                        value={
                          selectedOrder && selectedOrder.product.productName
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel>Order Platform</CustomRequiredLabel>
                    <CustomSubheadingh>
                      Select the platform for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        name="reviewscreenshot"
                        type="text"
                        placeholder="Order Platform"
                        id="outlined-required"
                        label="First Name"
                        helper
                        size="small"
                        disabled
                        value={
                          selectedOrder && selectedOrder.orderPlatform.platform
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {/* </>
     )} */}

                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Review Screenshot
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Upload the screenshot of the successful review of the
                      product.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      {/* <DescriptionInput
                      type="file"
                      size="small"
                      onChange={handleReviewScreenShot}
                      inputProps={{ accept: "image/*" }}
                    /> */}
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleReviewScreenShot}
                        multiple
                        className="bg-gray-10 border border-gray-300 text-gray-900 sm:text-sm rounded  block w-full p-2"
                        style={{ color: "transparent" }}
                      />
                      {formSubmitted && !selectedImages.length > 0 && (
                        <p className="input_text_error">
                          *Please upload the review screenshot.
                        </p>
                      )}
                      {errorMessage && <p className="input_text_error">{errorMessage}</p>}

                      <div>
                        {/* <input type="file" onChange={handleReviewScreenShot} multiple />
            <div>
                {selectedImages.map((image, index) => (
                    <img 
                        key={index}
                        src={URL.createObjectURL(image)}
                        alt={`Selected ${index}`}
                        style={{ height: '100px', width: '100px' }}
                    />
                ))}
            </div> */}
                      </div>
                      {selectedImages.map((image, index) => (
                        <div
                          key={index}
                          className="mt-5 flex justify-between items-center border-gray-300 border-[1px] rounded-lg"
                        >
                          <div className="flex justify-between p-1">
                            <img
                              src={image.url}
                              alt={`Selected ${index}`}
                              className="w-8 h-8 object-cover  border border-gray-300 self-center"
                              onClick={() => handleOpenZoomModal(image.url)}
                            />
                            <p className="ml-2">{image.file.name}</p>
                            <p className="ml-2">{image.originalName}</p>
                          </div>
                          <CloseIcon
                            onClick={() => removeImage(index)}
                            className="text-red-500 cursor-pointer ml-2"
                          />
                        </div>
                      ))}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      {/* Delivered & Product Screenshot */}
                      Delivered Screenshot
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Upload the delivery screenshot.
                      {/* Upload the delivery screenshot or product screenshot. */}
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      {/* <DescriptionInput
                      type="file"
                      size="small"
                      onChange={handleReviewScreenShot}
                      inputProps={{ accept: "image/*" }}
                    /> */}
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handledeliveredScreenShot}
                        multiple
                        className="bg-gray-10 border border-gray-300 text-gray-900 sm:text-sm rounded  block w-full p-2"
                        style={{ color: "transparent" }}
                      />
                      {formSubmitted && !selectedDeliveredImages.length > 0 && (
                        <p className="input_text_error">
                          *Please upload the delivery screenshot.
                        </p>
                      )}
                      {errorDelMessage && <p className="input_text_error">{errorDelMessage}</p>}
                      <div>
                        {/* <input type="file" onChange={handleReviewScreenShot} multiple />
            <div>
                {selectedImages.map((image, index) => (
                    <img 
                        key={index}
                        src={URL.createObjectURL(image)}
                        alt={`Selected ${index}`}
                        style={{ height: '100px', width: '100px' }}
                    />
                ))}
            </div> */}
                      </div>
                      {selectedDeliveredImages.map((image, index) => (
                        <div
                          key={index}
                          className="mt-5 flex justify-between items-center border-gray-300 border-[1px] rounded-lg"
                        >
                          <div className="flex justify-between p-1">
                            <img
                              src={image.url}
                              alt={`Selected ${index}`}
                              className="w-8 h-8 object-cover  border border-gray-300 self-center"
                              onClick={() => handleOpenZoomModal(image.url)}
                            />
                            {/* <p className="ml-2">{image.file.name}</p> */}
                            <p className="ml-2">{image.originalName}</p>
                          </div>
                          <CloseIcon
                            onClick={() => removeDeliveredImage(index)}
                            className="text-red-500 cursor-pointer ml-2"
                          />
                        </div>
                      ))}
                    </FormControl>
                  </Grid>
                </Grid>

                <SubmitButton
                  type={isDisabledNew ? "button" : "submit"}
                  label={isDisabledNew ? "Loading..." : "Submit"}
                  disabled={isDisabledNew}
                />
              </CardContent>
            </Card>
          </form>
        </div>
      </div>
    </>
  );
};
export default AddReviewMarq;
