import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TablePagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../tabel/DataTabel";
import ShortingTable from "../tabel/ShortingTabel";
import {
  useCampaignOrderCharts,
  useGetCampaignByOrderPlateformName,
  useGetCampaignByOrderProductName,
  useGetCampaignSatgeDetails,
  useGetCampaignStatsforBrand,
} from "../customHooks/customHook";

import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";

import CardComponent from "../../common/card/CardComponent";

import Skeleton from "@mui/material/Skeleton";
import { useParams } from "react-router-dom";
import {
  Collaboration,
  NoDataImage,
  Order,
  PaymentBlackHeader,
  Reimbursement,
} from "../IconsImport";
import { CustomRequiredLabel } from "../../common/styledCcomponents/styledInput";
const CampaignStatsView = () => {
  const { id } = useParams();
  const orderChart = useCampaignOrderCharts(id);

  const columns = [
    { id: "sno", label: "S.No.", minWidth: 100 },
    { id: "productname", label: "Product name", minWidth: 200 },
    { id: "totalrequest", label: "Total Requested Orders", minWidth: 50 },

    { id: "totalorders", label: "Total Completed Orders", minWidth: 100 },
    { id: "totalrvieworders", label: "Total Reviewed Orders", minWidth: 100 },
    {
      id: "totalCancelledOrReturned",
      label: "Total Cancelled Or Returned",
      minWidth: 200,
    },
  ];

  const columnsPlatform = [
    { id: "sno", label: "S.No.", minWidth: 100 },
    { id: "platformname", label: "Platform", minWidth: 200 },

    { id: "totalorders", label: "Total Completed Orders", minWidth: 100 },
    { id: "totalrvieworders", label: "Total Reviewed Orders", minWidth: 100 },
    {
      id: "totalCancelledOrReturned",
      label: "Total Cancelled Or Returned",
      minWidth: 200,
    },
  ];
  const columnsOrderData = [
    { id: "sno", label: "S.No.", minWidth: 50 },
    { id: "dateoforders", label: "Date Of Orders", minWidth: 100 },
    { id: "numberoforders", label: "No. Of Orders", minWidth: 100 },
  ];
  const { allstatus: campaignAllStats, loading: campaignAllStatsLoading } =
    useGetCampaignStatsforBrand(id);


  const orderByPlatformName = useGetCampaignByOrderPlateformName(id);
  const realPlatformData = orderByPlatformName?.allstatus.map(
    (item, index) => ({
      sno: index + 1,
      platformname: item.platformName && item.platformName,
      totalorders: item.totalOrders && item.totalOrders,
      totalrvieworders:
        item.totalClosedOrReimbursed && item.totalClosedOrReimbursed,
      totalCancelledOrReturned:
        item.totalCancelledOrReturned && item.totalCancelledOrReturned,
    })
  );

  const { allstatus: campaignDetails, loading: campaignDetailsLoading } =
    useGetCampaignSatgeDetails(id);
  // console.log(campaignDetails);

  // console.log(campaignPendingPayment);
  const orderByProductName = useGetCampaignByOrderProductName(id);

  const realData = orderByProductName?.allstatus.map((item, index) => ({
    sno: index + 1,
    productname: item.productName && item.productName,
    totalrequest: item.totalDesiredOrders && item.totalDesiredOrders,
    totalorders: item.totalOrders && item.totalOrders,
    totalrvieworders:
      item.totalClosedOrReimbursed && item.totalClosedOrReimbursed,
    totalCancelledOrReturned:
      item.totalCancelledOrReturned && item.totalCancelledOrReturned,
  }));
  const realOrderData = orderChart?.allstatus.map((item, index) => ({
    sno: index + 1,
    dateoforders: item.date && item.date,
    numberoforders: item.totalOrders && item.totalOrders,
  }));
  return (
    <>
      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            {" "}
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Campaign", href: "/brand/campaign" },
                { name: "Stats", href: `/campaign/stats/${id}` },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              {campaignAllStatsLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "30px" }}
                  width={150}
                />
              ) : (
                <p>{campaignAllStats[0]?.campaignName}</p>
              )}
            </div>
          </div>
          {campaignDetails?.status && (
            <div className="status_content">
              {campaignDetailsLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "14px" }}
                  width={100}
                />
              ) : (
                <p>
                  {campaignDetails?.status
                    ? campaignDetails?.status?.status
                    : ""}
                </p>
              )}
            </div>
          )}
          <div className="card_container">
            {/* {campaignDetails?.campaignType?.campaignType === "Review" ? ( */}
            <>
              <CardComponent
                logoColor="purple"
                image={Order}
                data={campaignAllStats[0] && campaignAllStats[0]?.noOfContent}
                heading="Requested Orders"
                width="250px"
                loading={campaignAllStatsLoading}
              />
              <CardComponent
                logoColor="blue"
                image={Order}
                data={ campaignAllStats[0] && campaignAllStats[0]?.totalPlacedOrders}
                heading="Placed Orders"
                loading={campaignAllStatsLoading}
                width="250px"
              />

              <CardComponent
                logoColor="pink"
                image={Reimbursement}
                heading="Cancelled/Returned Orders"
                data={campaignAllStats[0] && campaignAllStats[0]?.totalCancelledOrders + campaignAllStats[0]?.totalReturnedOrders}
                loading={campaignAllStatsLoading}
                width="250px"
              />

              <CardComponent
                logoColor="pink"
                image={Reimbursement}
                heading="Completed Orders"
                data={campaignAllStats[0] && campaignAllStats[0]?.totalPlacedOrders - campaignAllStats[0]?.totalCancelledOrders - campaignAllStats[0]?.totalReturnedOrders}
                loading={campaignAllStatsLoading}
                width="250px"
              />

              <CardComponent
                logoColor="green"
                image={Order}
                heading="Pending Orders"
                data={campaignAllStats[0] && campaignAllStats[0]?.totalPendingOrders}
                color={"red"}
                loading={campaignAllStatsLoading}
                width="250px"
              />
              <CardComponent
                logoColor="pink"
                image={Order}
                heading="Reviewed Orders"
                data={campaignAllStats[0] && campaignAllStats[0]?.totalReviewedOrders}
                loading={campaignAllStatsLoading}
                width="250px"
              />

              <CardComponent
                logoColor="pink"
                image={Reimbursement}
                heading="Total Cost"
                data={campaignAllStats[0] && campaignAllStats[0]?.totalCampaignCost}
                loading={campaignAllStatsLoading}
                width="250px"
              />
              <CardComponent
                logoColor="pink"
                image={Reimbursement}
                heading="Received Payment"
                data={campaignAllStats[0] && campaignAllStats[0]?.receivedPayment}
                loading={campaignAllStatsLoading}
                width="250px"
              />
              <CardComponent
                logoColor="pink"
                image={PaymentBlackHeader}
                heading="Pending Payment"
                data={campaignAllStats[0] && campaignAllStats[0]?.pendingPayment?.toFixed(2)}
                loading={campaignAllStatsLoading}
                color={"red"}
                width="250px"
              />
              <CardComponent
                logoColor="pink"
                image={PaymentBlackHeader}
                heading="Exhausted Order Cost"
                data={campaignAllStats[0] && campaignAllStats[0]?.exhaustedCost?.toFixed(2)}
                loading={campaignAllStatsLoading}
                width="250px"
              />

              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                {/* <div className="brand_details_main_container"> */}
                {/* <div className="brand_details_container"> */}
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <h1 className="country_text">Daily Orders (Last 10 Days)</h1>

                  {/* <Card sx={{ width: "100%" }}>
                      <CardContent> */}
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker />
    </LocalizationProvider> */}
                  {/* <ResponsiveContainer width="100%" height={300}> */}

                  {/* {orderChart?.allstatus && orderChart?.allstatus.length < 0 ? ( */}
                    <ShortingTable
                      columns={columnsOrderData}
                      // loading={activeCampaignDetailsLoading}
                      data={realOrderData}
                    />
                  {/* ) : ( */}
                    {/* <div>
                      <CustomRequiredLabel>
                        No Data Available
                      </CustomRequiredLabel>
                      <img src={NoDataImage} />
                    </div>
                  )} */}
                  {/* </ResponsiveContainer> */}
                  {/* </CardContent>
                    </Card> */}
                </Grid>
                <Grid item xs={12} sm={8} sx={{ paddingBottom: "15px" }}>
                  {/* <div className="brand_details_main_container"> */}
                  {/* <div className="brand_details_container"> */}
                  <h1 className="country_text">Total Orders By Product</h1>
                  <ShortingTable
                    columns={columns}
                    // loading={activeCampaignDetailsLoading}
                    data={realData}
                  />

                  <Divider sx={{ marginY: 2, borderBottom: "none" }} />
                  <h1 className="country_text">Total Orders By Platform</h1>
                  <ShortingTable
                    columns={columnsPlatform}
                    // loading={activeCampaignDetailsLoading}
                    data={realPlatformData}
                  />
                  {/* </div> */}
                </Grid>
              </Grid>
            </>
            {/* ) : null} */}
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};
export default CampaignStatsView;
